/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Elements
import { Image, ParseContent, GravityForm } from 'components/shared'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'

// Images
import BackImg from 'img/back-logo.png'
import ShineImg from 'img/shine.png'

const Content = styled(ParseContent)`
  & table {
    width: 100%;

    img {
      margin-right: 30px;
    }

    td + td {
    }

    & tr {
      display: flex;
      flex-wrap: wrap;
    }

    & td {
      @media screen and (max-width: 991px) {
        width: 100% !important;
        padding-top: 20px;
        padding-bottom: 20px;
      }

      font-weight: 300;
      font-family: ${(props) => props.theme.font.family.secondary};
      letter-spacing: 2px;
      font-size: ${(props) => props.theme.font.size.sm};

      b,
      strong {
        font-family: ${(props) => props.theme.font.family.main};
        font-size: ${(props) => props.theme.font.size.m};
        letter-spacing: 0.5px;
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => (
  <Layout activeMenu="Contact">
    <SEO yoast={yoast} path={path} article />
    <Section1>
      <ShineImage>
        <Image src={ShineImg} style={{ width: '100%' }} />
      </ShineImage>
      <div className="container">
        <div className="row">
          <div className="col-xl-11 mx-auto">
            <h1>Contact</h1>
          </div>
        </div>
        <div className="row pt-lg-5 pt-3">
          <div className="col-xl-10 mx-auto">
            <Content content={acf.content.description} />
          </div>
        </div>
      </div>
    </Section1>
    <UBXImage>
      <Image src={BackImg} style={{ width: '100%' }} />
    </UBXImage>
    <Section2>
      <div className="container">
        <div className="d-flex flex-wrap justify-content-center">
          <Form>
            <h2 className="color-main font-size-l mb-5">Neem contact op</h2>
            <GravityForm id={1} />
          </Form>
          <MapContainer>
            <iframe
              title="Uw Business Online B.V."
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19550.27728655348!2d5.36148795!3d52.22913!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x887cbf3579bbd34!2sUw%20Business%20Online!5e0!3m2!1snl!2snl!4v1602166736377!5m2!1snl!2snl"
              width="100%"
              height="100%"
              frameBorder="0"
              allowFullScreen=""
              aria-hidden="false"
            />
          </MapContainer>
        </div>
      </div>
    </Section2>
  </Layout>
  )

const Section1 = styled.div`
  margin-top: 140px;
  h1 {
    font-family: ${(props) => props.theme.font.family.main};
    font-size: ${(props) => props.theme.font.size.xxl};
  }
  @media screen and (max-width: 768px) {
    margin-top: 40px;
    h1 {
      font-family: ${(props) => props.theme.font.family.main};
      font-size: ${(props) => props.theme.font.size.xl};
    }
  }
`

const Section2 = styled.div`
  margin-top: 120px;
  padding-bottom: 180px;
`

const UBXImage = styled.div`
  /* height: 420px; */
  width: 80%;
  margin-top: 25px;
  position: absolute;
  background-size: contain !important;
  background-position-x: 0 !important;
  @media screen and (max-width: 768px) {
    margin-top: 15px;
    width: 100%;
  }

  & .gatsby-image-wrapper {
    max-height: 250px;
    overflow: initial !important;

    & img {
      max-height: 350px;
      object-fit: contain;
    }
  }
`

const Form = styled.div`
  background: white;
  width: 600px;
  h3 {
    font-size: ${(props) => props.theme.font.size.xm};
    font-family: ${(props) => props.theme.font.family.main};
  }
  height: fit-content;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  @media screen and (max-width: 991px) {
    padding: 30px 30px 20px 30px;
  }

  button {
    @media screen and (max-width: 991px) {
      padding: 0 20px;
    }
  }

  @media (min-width: 992px) {
    padding: 60px 60px 40px 60px;
  }

  @media (max-width: 991px) {
    padding: 1rem;
  }
`

const MapContainer = styled.div`
  width: 400px;
  height: 340px;
  margin-top: 40px;
  @media screen and (max-width: 1200px) {
    margin-top: 0px;
    width: 100%;
  }
`

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        content {
          description
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate

const ShineImage = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`
